<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
// import router from './router';
// import Home from './views/HomeView.vue'
export default {
  name: 'App',
  components: {
    // Home,
    // router
  }
}
</script>
<style>
/* import './assets/css/style.css' */
#app {
  font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
}
</style>
