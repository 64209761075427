import axios from 'axios'
import store from '../store'
import router from '../router'
import { Message } from 'element-ui'

// 将 snake_case 转为 camelCase
function snake2camel (data) {
  if (typeof data !== 'object' || !data) { return data }
  if (Array.isArray(data)) { return data.map(item => snake2camel(item)) }
  const newData = {}
  for (const key in data) {
    const newKey = key.replace(/_([a-z])/g, (p, m) => m.toUpperCase())
    newData[newKey] = snake2camel(data[key])
  }
  return newData
}

// 将 camelCase 转为 snake_case
function camel2snake (data) {
  if (typeof data !== 'object' || !data) { return data }
  if (Array.isArray(data)) { return data.map(item => camel2snake(item)) }
  const newData = {}
  for (const key in data) {
    const newKey = key.replace(/([A-Z])/g, (p, m) => `_${m.toLowerCase()}`)
    newData[newKey] = camel2snake(data[key])
  }
  return newData
}

const service = axios.create({
  baseURL: '/api/v1',
  timeout: 15000
})

service.interceptors.request.use(function (request) {
  if (request.requireAuth) {
    let token = store.state.token
    if (!token) {
      token = localStorage.getItem('token')
    }
    if (token) {
      request.headers.Authorization = 'Token ' + token
    }
  }

  if (request.params) { // get请求参数
    const params = camel2snake(request.params)
    request.params = params
  } else if (request.data) { // post请求参数
    const data = camel2snake(request.data)
    request.data = data
  }

  return request
})

const isJsonObj = function (obj) {
  const isjson = typeof (obj) === 'object' && Object.prototype.toString.call(obj).toLowerCase() === '[object object]' && !obj.length
  return isjson || Array.isArray(obj)
}

service.interceptors.response.use(function (response) {
  let data = response.data
  // const status = response.status
  // const config = response.config
  // const redirect = ''

  if (isJsonObj(data)) {
    data = snake2camel(data)
  }

  return data
}, function (err) {
  const status = err.response.status
  const data = err.response.data
  if (status === 401) {
    store.commit('handleLogout')
    Message.error(data)
    if (router.currentRoute.path !== '/login-register') {
      router.push({ path: '/login-register' })
    }
    return Promise.reject(err)
  } else if (status === 403) {
    Message.error('当前操作权限不足')
    return Promise.reject(err)
  } else {
    let message = '系统错误，请联系管理员！'
    if (isJsonObj(data)) {
      message = data.message
    } else if (typeof data === 'string') {
      message = data
    }
    Message.error(message)
    return Promise.reject(err)
  }
})

export default service
