<template>
  <el-descriptions class="margin-top" title="" :column="3" :size="size">
      <template slot="extra">
          <el-button type="primary" size="mini" @click="modifyNickName()">修改昵称</el-button>
      </template>
      <el-descriptions-item label="用户名">{{ username }}</el-descriptions-item>
      <el-descriptions-item label="手机号">{{ phone }}</el-descriptions-item>
      <el-descriptions-item label="昵称">{{ nickname ? nickname : "未设置" }}
      </el-descriptions-item>
      <!-- <el-descriptions-item label="备注">
          <el-tag size="mini">学校</el-tag>
      </el-descriptions-item> -->
      <el-descriptions-item label="积分">
          <el-tag size="mini">{{ integral }}</el-tag>
      </el-descriptions-item>
  </el-descriptions>
</template>

<script>
import { userInfoApi, updateUserApi } from '../api/user'

export default {
  data () {
    return {
      size: '',
      username: '',
      phone: '',
      nickname: '',
      integral: 0
    }
  },
  created () {
    userInfoApi()
      .then((res) => {
        this.username = res.username
        this.phone = res.phone
        this.nickname = res.nickname
        this.integral = res.integral
      })
      .catch(function (err) {
        if (err.response) {
          console.log(err.response)
        }
      })
  },
  methods: {
    modifyNickName () {
      this.$prompt('请输入昵称', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /^.{2,16}$/,
        inputErrorMessage: '昵称长度为2-16个字符'
      }).then(({ value }) => {
        updateUserApi({ nickname: value })
          .then((res) => {
            this.nickname = value
            this.$store.commit('setNickname', value)
          })
          .catch(function (err) {
            if (err.response) {
              console.log(err.response)
            }
          })
      }).catch(() => {
        // this.$message({
        //     type: 'info',
        //     message: '取消输入'
        // });
      })
    }
  }
}
</script>
