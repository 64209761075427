<template>
  <el-container>
    <el-aside width="200px" :style="asideStyle">
      <div style="height: 39px; display: flex; align-items: center; border-bottom: 1px solid #dcdfe6; padding-left: 20px; background-color: rgb(245, 246, 246);">
        <img src="../assets/logo32.png" alt="" width="20" height="20" style="line-height: 39px;">
      <span class="formTitle" style="padding-left: 10px;">欢迎使用高分数</span>
      </div>
      <el-menu router :default-active="$route.path" :unique-opened="true">
        <el-menu-item index="/home/dashboard">
          <i class="el-icon-odometer"></i>
          <span slot="title">阅卷系统</span>
        </el-menu-item>
        <el-submenu index="2">
          <template slot="title">
            <i class="el-icon-aim"></i>
            <span>学校管理</span>
          </template>
          <el-menu-item index="/home/school">我的学校</el-menu-item>
          <el-menu-item index="/home/student" :disabled="$store.state.userRole === 'teacher'">学生管理</el-menu-item>
          <el-menu-item index="/home/teacher" :disabled="$store.state.userRole === 'teacher'">老师管理</el-menu-item>
          <el-menu-item index="/home/class" :disabled="$store.state.userRole === 'teacher'">班级管理</el-menu-item>
        </el-submenu>

        <el-submenu index="3">
          <template slot="title">
            <i class="el-icon-shopping-bag-1"></i>
            <span>考试管理</span>
          </template>
          <el-menu-item index="/home/exam" :disabled="$store.state.userRole === 'teacher'">考试列表</el-menu-item>
          <el-menu-item index="/home/exam-sys" v-if="$store.state.isAdmin">更多操作</el-menu-item>
        </el-submenu>

        <el-submenu index="4">
          <template slot="title">
            <i class="el-icon-truck"></i>
            <span>阅卷管理</span>
          </template>
          <el-menu-item index="/home/marking-exam">待阅考试</el-menu-item>
          <!-- <el-menu-item index='/home/answer-sheet' :disabled="$store.state.userRole === 'teacher'">阅卷结果</el-menu-item> -->
        </el-submenu>

        <el-menu-item index="/home/call-me">
          <i class="el-icon-phone"></i>
          <span slot="title">请联系我</span>
        </el-menu-item>
      </el-menu>
    </el-aside>
    <el-container>
      <el-header style="line-height: 40px; border-bottom: 1px solid #dcdfe6; height: 40px; background-color: rgb(245, 246, 246);">
        <!-- <el-breadcrumb ref="nav123" separator-class="el-icon-arrow-right" style="display: inline-block;">
          <el-breadcrumb-item v-for="(item) in levelList" :key="item.path" @click.native="breadcrumbClick(item)">
            {{ item.meta.title }}
          </el-breadcrumb-item>
        </el-breadcrumb> -->
        <!-- <div style="width: 50%; display: inline-flex; align-items: center; color: #303133;">
          欢迎使用我要满分阅卷系统
        </div> -->
        <el-breadcrumb style="display: inline-block;" separator="/">
          <el-breadcrumb-item v-for="(item,index) in levelList" :key="item.path">
            <span v-if="item.redirect==='noRedirect'||index==levelList.length-1"
              class="no-redirect">{{ item.meta.title }}</span>
            <a v-else @click.prevent="handleLink(item)">{{ item.meta.title }}</a>
          </el-breadcrumb-item>
        </el-breadcrumb>

        <div style="float: right;">
          <i class="el-icon-s-custom" style="font-size:20px; margin-right:10px"></i>
          <el-dropdown placement="bottom" @command="handleCommand">
            <span class="el-dropdown-link" style="font-size:16px;">
              {{ $store.state.nickname ? $store.state.nickname : $store.state.username }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="a">个人中心</el-dropdown-item>
              <el-dropdown-item command="b">登出</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </el-header>
      <el-main>
        <router-view :key="$route.fullPath"></router-view>
      </el-main>
    </el-container>
    <!-- <el-footer>Footer</el-footer> -->
    <el-dialog title="个人中心" :visible.sync="meVisible">
      <me></me>
    </el-dialog>
  </el-container>
</template>
<script>
import me from '../components/Me'

export default {
  name: 'HomeView',
  components: {
    me
  },
  data () {
    return {
      levelList: null,
      meVisible: false,
      asideStyle: { height: window.innerHeight - 80 + 'px' }
    }
  },
  computed: {
    routes () {
      return this.$router.options.routes
    }
  },
  watch: {
    $route (to, from) {
      this.getBreadcrumb()
    }
  },
  created () {
    this.getBreadcrumb()
    window.addEventListener('resize', this.handleResize)
    // document.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  methods: {
    handleCommand (command) {
      if (command === 'a') {
        this.meVisible = true
      } else if (command === 'b') {
        this.$store.commit('handleLogout')
        this.$router.push({ path: '/login-register' })
      }
    },
    handleResize () {
      // this.tableheight.height = window.innerHeight - 80 + 'px'
      this.asideStyle.height = window.innerHeight - 80 + 'px' // 获取浏览器高度减去顶部导航栏
    },
    getBreadcrumb () {
      // const matched = this.$route.matched.filter(item => item.meta.title)
      // // matched[matched.length - 1].path = '1'
      // // matched[matched.length - 1].redirect = '1'
      // // alert(JSON.stringify(matched))
      // this.levelList = matched
      // this.$nextTick(() => {
      //   // this.$refs.nav123.lastElementChild.attributes.to = ''
      //   // this.$refs.nav123.$el.style.backgroud = 'red'
      //   // this.$refs.nav123.$children[1].$el.style.role = ''
      // })

      // this.$refs.nav.lastElementChild.attributes.to = ''

      // https://juejin.cn/post/7109358950650216456
      let matched = this.$route.matched.filter(
        (item) => item.meta && item.meta.title
      )
      const first = matched[0]
      const last = matched[matched.length - 1]
      if (!this.isDashboard(first)) {
        matched = [
          // { path: '/dashboard', meta: { title: '临床用血' } }
        ].concat(matched)
      }
      this.levelList = matched.filter(
        (item) => item.meta && item.meta.title && item.meta.breadcrumb !== false
      )
      const pushlist = []
      if (last.meta.parent && last.meta.parent.length > 0) {
        last.meta.parent.forEach((element) => {
          const selectroute = this.findRoute(element)
          if (selectroute != null) {
            pushlist.push(selectroute)
          }
        })
      }
      this.levelList.splice(1, 0, ...pushlist)
    },
    isDashboard (route) {
      const name = route && route.name
      if (!name) {
        return false
      }
      return (
        name.trim().toLocaleLowerCase() === 'Dashboard'.toLocaleLowerCase()
      )
    },
    handleLink (item) {
      const { redirect, path } = item
      if (item.meta.noredirect || item.meta.noredirect !== undefined) {
        return
      }
      if (redirect) {
        this.$router.push(redirect)
        return
      }
      this.$router.push(path)
    },
    findRoute (name) {
      let routes = null
      // alert(JSON.stringify(this.routes))
      for (let i = 0; i < this.routes.length; i++) {
        // alert(JSON.stringify(this.routes[i]))
        // alert('child' + JSON.stringify(this.routes[i].children))
        if (this.routes[i].children && this.routes[i].children.length > 0) {
          // alert(3)
          // alert(JSON.stringify(this.routes[i].children))
          routes = this.routes[i].children.find((e) => e.name === name)
        }
      }
      return routes
    },
    breadcrumbClick (item) {
      // this.$router.push({
      //   path: item.path
      // })
    }
  }
}
</script>
<style>
.homeHeader {
  background-color: rgb(245, 246, 246);
  /* box-shadow: 0 2px 12px 0 rgba(102, 100, 100, 0.1); */
  line-height: 40px;
  border-bottom: 1px solid #dcdfe6;
}

/* .el-container {
  height: 100%;
} */

.el-menu {
  padding-top: 5px;
  /* height: 100%; */
  border: 0 !important;
}

.el-aside {
  border-right: 1px solid #dcdfe6;
}

.tipFont {
  font-size: 12px;
  color: #606266;
}

/* .formTitle {
  font-size: 14px;
  color: #606266;
  padding-right: 10px;
}

.rowPaddingLeft {
  padding-left: 10px;
} */

/* .header-avatar {
  float: right;
  margin-right: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
} */

.topButton {
  width: 100%;
  padding-bottom: 10px;
}

td {
  text-align: center;
  white-space: nowrap;
}

/* .inputWidth {
  width: 120px;
} */

/* .app-breadcrumb.el-breadcrumb {
  display: inline-block;
  font-size: 14px;
  line-height: 50px;
  margin-left: 8px;

  .no-redirect {
    color: #97a8be;
    cursor: text;
  } */

</style>
