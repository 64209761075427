import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userId: sessionStorage.getItem('userId'),
    isAdmin: JSON.parse(sessionStorage.getItem('isAdmin')),
    username: sessionStorage.getItem('username'),
    nickname: sessionStorage.getItem('nickname'),
    userRole: sessionStorage.getItem('userRole'),
    token: sessionStorage.getItem('token')
  },
  getters: {
  },
  mutations: {
    setUserName (state, username) {
      state.username = username
      sessionStorage.setItem('username', username)
    },
    setNickname (state, nickname) {
      state.nickname = nickname
      sessionStorage.setItem('nickname', nickname)
    },
    handleTokenResponse (state, res) {
      state.token = res.token
      state.username = res.username
      const strings = res.token.split('.') // 截取token，获取载体
      const payload = JSON.parse(decodeURIComponent(escape(window.atob(strings[1].replace(/-/g, '+').replace(/_/g, '/'))))) // 解析，需要吧‘_’,'-'进行转换否则会无法解析
      state.userId = payload.user_id
      state.isAdmin = res.integral === -1
      state.nickname = res.nickname
      state.userRole = res.role
      sessionStorage.setItem('userId', state.userId)
      sessionStorage.setItem('username', state.username)
      sessionStorage.setItem('nickname', state.nickname)
      sessionStorage.setItem('isAdmin', state.isAdmin)
      sessionStorage.setItem('token', state.token)
      sessionStorage.setItem('userRole', state.userRole)
    },
    handleLogout (state) {
      state.token = ''
      // state.refreshToken = ''
      state.username = ''
      state.nickname = ''
      state.isAdmin = false
      state.userId = ''
      state.userRole = ''
      sessionStorage.clear()
    }
  },
  actions: {
  },
  modules: {
  }
})
