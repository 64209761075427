import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import('../views/Index.vue')
  },
  {
    path: '/login-register',
    name: 'loginRegister',
    component: () => import('../views/LoginRegister.vue')
  },
  {
    path: '/home',
    name: 'home',
    component: Home,
    redirect: 'dashboard',
    meta: { title: '首页', requireAuth: true },
    children: [
      {
        path: 'dashboard',
        name: 'dashboard',
        component: () => import('../views/Dashboard.vue'),
        meta: { requireAuth: true }
      },
      {
        path: 'school',
        name: 'school',
        component: () => import('../views/School.vue'),
        meta: { title: '我的学校', requireAuth: true }
      },
      {
        path: 'class',
        name: 'class',
        component: () => import('../views/Class.vue'),
        meta: { title: '班级管理', requireAuth: true }
      },
      {
        path: 'student',
        name: 'student',
        component: () => import('../views/Student.vue'),
        meta: { title: '学生管理', requireAuth: true }
      },
      {
        path: 'teacher',
        name: 'teacher',
        component: () => import('../views/Teacher.vue'),
        meta: { title: '老师管理', requireAuth: true }
      },
      {
        path: 'exam',
        name: 'exam',
        component: () => import('../views/Exam.vue'),
        meta: { title: '考试列表', requireAuth: true }
      },
      {
        path: 'answer-sheet',
        name: 'answerSheet',
        component: () => import('../views/AnswerSheet.vue'),
        meta: { title: '阅卷结果', requireAuth: true, parent: ['exam'] }
      },
      {
        path: 'exam-sys',
        name: 'examSys',
        component: () => import('../views/ExamSys.vue'),
        meta: { title: '更多操作', requireAuth: true }
      },
      {
        path: 'marking',
        name: 'marking',
        component: () => import('../views/Marking.vue'),
        meta: { title: '阅卷台', requireAuth: true, parent: ['markingExam'] }
      },
      {
        path: 'marking-exam',
        name: 'markingExam',
        component: () => import('../views/MarkingExam.vue'),
        meta: { title: '待阅考试', requireAuth: true }
      },
      {
        path: 'call-me',
        name: 'callMe',
        component: () => import('../views/CallMe.vue'),
        meta: { title: '请联系我', requireAuth: true }
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
