import request from './request'

// 获取考试列表
export const registerApi = (params) => request({
  requireAuth: false,
  url: '/users',
  method: 'post',
  data: params
})

// 获取考试列表
export const loginApi = (params) => request({
  requireAuth: false,
  url: '/users/login',
  method: 'post',
  data: params
})

// 获取考试列表
export const userInfoApi = () => request({
  requireAuth: true,
  url: '/user',
  method: 'get'
})

// 获取考试列表
export const updateUserApi = (params) => request({
  requireAuth: true,
  url: '/user',
  method: 'put',
  data: params
})

// 获取考试列表
export const updatePasswordApi = (params) => request({
  requireAuth: false,
  url: '/user',
  method: 'put',
  data: params
})
